@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700;800&family=Merriweather:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap);
*{ margin: 0px; padding: 0px; box-sizing: border-box; }
.input-cntr {
}

.errorMsg {
  /* height: 1.375em; */
}

.auth-form-cntr {
  margin: auto;
  max-width: 534px;
  padding: 0px 40px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.2); */
}

.desktop {
  max-width: 1366px;
  margin: 0 auto;
}

.desktop,
.desktop .auth-cntr {
  height: 100%;
}

.desktop .login-artifact {
  background: #fff9e5 0% 0% no-repeat padding-box;
}

.desktop .rl-btns {
  display: flex;
}

.desktop .rl-btns .RegistrationBtn,
.desktop .rl-btns .loginBtn {
  margin-top: 0 !important;
}

.desktop .rl-btns .login-link-cntr {
  align-items: flex-start !important;
  margin-left: 25px !important;
  justify-content: center;
}

.mobile .login-artifact img {
  height: 313px;
}

.errorMsg {
  color: #e6111b;
  font-size: 0.875rem;
  padding: 5px 0;
}

#header-bar {
  padding: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.desktop #header-bar {
  padding: 35px 24px;
}

.site-logo-cntr {
  display: flex;
  align-items: center;
}

.site-logo-cntr img {
  max-height: 100%;
}

.menu {
  cursor: pointer;
}

.site-title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

.merriwether {
  font-family: "Merriweather", serif;
}

.red {
  color: #ff3d00;
}

#header-bar a {
  color: #2d2d2d;
  cursor: pointer;
}

.f14 {
  font: normal normal normal 14px/22px Merriweather Sans;
}
.f15 {
  font: normal normal normal 15px/25px Merriweather Sans;
}
.f15_b {
  font: normal normal bold 15px/25px Merriweather Sans;
}

.desktop .highlighted-patch {
  padding: 30px;
}

.mobile .highlighted-patch {
  padding: 20px;
}

.highlighted-patch {
  margin-top: 30px;
  background-color: #f2f2f2;
  margin-bottom: 60px;
}

p {
  font: normal normal normal 14px/22px Merriweather Sans;
  margin-bottom: 15px;
  color: #505050;
}

li {
  font: normal normal normal 14px/20px Merriweather Sans;
  margin-bottom: 10px;
  color: #505050;
  margin-left: 1rem;
}

.external-links a {
  color: black;
}

html, body, #root{height:100%}
* {
    font-family: "Merriweather Sans", sans-serif;
}
.-hf {
    font-family: "Merriweather", serif;
}

.footer {
    padding: 30px;
    /* position: absolute; */
    /* bottom: 0; */
    /* width: 100%; */
}

.line-clamp {
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.line-clamp-desktop {
    -webkit-line-clamp: 4;
}

.line-clamp-mobile {
    -webkit-line-clamp: 6;
}

.e-link:hover {
    background: transparent;
}

.external-links p {
    line-height: 1.7;
}

