.footer {
    padding: 30px;
    /* position: absolute; */
    /* bottom: 0; */
    /* width: 100%; */
}

.line-clamp {
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.line-clamp-desktop {
    -webkit-line-clamp: 4;
}

.line-clamp-mobile {
    -webkit-line-clamp: 6;
}

.e-link:hover {
    background: transparent;
}

.external-links p {
    line-height: 1.7;
}
