#header-bar {
  padding: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.desktop #header-bar {
  padding: 35px 24px;
}

.site-logo-cntr {
  display: flex;
  align-items: center;
}

.site-logo-cntr img {
  max-height: 100%;
}

.menu {
  cursor: pointer;
}

.site-title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

.merriwether {
  font-family: "Merriweather", serif;
}

.red {
  color: #ff3d00;
}

#header-bar a {
  color: #2d2d2d;
  cursor: pointer;
}
